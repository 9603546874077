import React from "react";
import { Redirect } from "react-router-dom";

// Layout Types
import { DefaultLayout } from "./layouts";

// Route Views
import EstratosView from "./views/EstratoStats";
import TipoViviendaView from "./views/TipoVivienda";
import ServiciosPublicosView from "./views/Servicios_publicos";
import BienestarView from "./views/Bienestar";
import Hacinamiento from "./views/Hacinamiento";
import DemografiaView from "./views/Demografia";
import SociedadView from "./views/Social";
import MigrationView from "./views/Migracion";
import SecurityView from "./views/SeguridadSocial";
import EducacionView from "./views/Educacion";
import MercadoLaboralView from "./views/MercadoLaboral";
import PercepcionEconomicaView from "./views/PercepcionEconomica";
import OtrosIngresosView from "./views/OtrosIngresos";
import  DemografiaMap  from "./views/DemografiaMap";

export default [
  {
    path: "/",
    exact: true,
    layout: DefaultLayout,
    component: () => <Redirect to="/estratos" />
  },
  {
    path: "/estratos",
    layout: DefaultLayout,
    component: EstratosView
  },

  {
    path: "/tipo-vivienda",
    layout: DefaultLayout,
    component: TipoViviendaView
  },

  {
    path: "/servicios-publicos",
    layout: DefaultLayout,
    component: ServiciosPublicosView
  },

  {
    path: "/bienestar",
    layout: DefaultLayout,
    component: BienestarView
  },

  {
    path: "/hacinamiento",
    layout: DefaultLayout,
    component: Hacinamiento
  },

  {
    path: "/demografia",
    layout: DefaultLayout,
    component: DemografiaView
  },

  {
    path: "/sociedad",
    layout: DefaultLayout,
    component: SociedadView
  },

  {
    path: "/migracion",
    layout: DefaultLayout,
    component: MigrationView
  },

  {
    path: "/seguridad-social",
    layout: DefaultLayout,
    component: SecurityView
  },

  {
    path: "/educacion",
    layout: DefaultLayout,
    component: EducacionView
  },

  {
    path: "/mercado-laboral",
    layout: DefaultLayout,
    component: MercadoLaboralView
  },

  {
    path: "/percepcion-economica",
    layout: DefaultLayout,
    component: PercepcionEconomicaView
  },

  {
    path: "/otros-ingresos",
    layout: DefaultLayout,
    component: OtrosIngresosView
  },

  // { path: "/mapa-demografico", layout: DefaultLayout, component: DemografiaMap }
];
