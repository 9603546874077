export default function() {
  return [

    // {
    //   title: "Mapa Demografico",
    //   to: "/mapa-demografico",
    //   htmlBefore: '<i class="material-icons">map</i>',
    //   htmlAfter: ""
    // },

    {
      title: "Estrato Social",
      to: "/estratos",
      htmlBefore: '<i class="material-icons">hiking</i>',
      htmlAfter: ""
    },
    {
      title: "Tipo de Vivienda",
      htmlBefore: '<i class="material-icons">home</i>',
      to: "/tipo-vivienda",
    },
    {
      title: "Servicios Públicos",
      htmlBefore: '<i class="material-icons">business</i>',
      to: "/servicios-publicos",
    },
    {
      title: "Bienestar",
      htmlBefore: '<i class="material-icons">favorite</i>',
      to: "/bienestar",
    },

    {
      title: "Hacinamiento",
      htmlBefore: '<i class="material-icons">people</i>',
      to: "/hacinamiento",
    },
    {
      title: "Demografía",
      htmlBefore: '<i class="material-icons">table_chart</i>',
      to: "/demografia",
    },
    {
      title: "Sociedad",
      htmlBefore: '<i class="material-icons">people</i>',
      to: "/sociedad",
    },

    {
      title: "Migración",
      htmlBefore: '<i class="material-icons">flight</i>',
      to: "/migracion",
    },

    {
      title: "Seguridad Social",
      htmlBefore: '<i class="material-icons">groups</i>',
      to: "/seguridad-social",
    },


    {
      title: "Educación",
      htmlBefore: '<i class="material-icons">school</i>',
      to: "/educacion",
    },

    {
      title: "Mercado Laboral",
      htmlBefore: '<i class="material-icons">work</i>',
      to: "/mercado-laboral",
    },

    {
      title: "Percepción Económica ",
      htmlBefore: '<i class="material-icons">money</i>',
      to: "/percepcion-economica",
    },

    {
      title: "Otros Ingresos",
      htmlBefore: '<i class="material-icons">money</i>',
      to: "/otros-ingresos",
    },

  ];
}
