export const PostsListOne = [
    {
      backgroundImage:
        "https://capturadorbarrancas.netlify.app/static/media/logoempresa.f22bfe6c.png",
      category: "",
      categoryTheme: "dark",
      author: "Anna Kunis",
      authorAvatar: require("../images/avatars/1.jpg"),
      title: "Centro de Consulturoria y Estadistica",
      body: "",
      date: ""
    },
   {
     backgroundImage: "https://dashboardbarrancas.consultoriasdatacenter.com/logoalcaldia.jpeg",
     category: "",
     categoryTheme: "dark",
     author: "Anna Kunis",
     authorAvatar:"",
     body:
       "",
     date: ""
   },
  ];